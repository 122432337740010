.form-check{
    text-align: start;
}
.chip { 
	background: #96d481 !important;
    color: black !important;
}
.optionContainer .highlight,
.optionContainer li:hover{
    background: #96d481 !important;
    color: black !important;
}

.btn-clear-filter{
    text-align: end;
}
.btn-clear-filter button{
    color: black;
    background: #96d481;
    border: 1px solid black;
}
.btn-clear-filter button:hover{
    color: white;
    background: #346d21;
    border: 1px solid black;
}
#filterTicker:checked{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    background-color: #96d481;
    border-color: black;
}


@media only screen and (min-width: 992px) {
    #filter1,
    #filter2,
    #filter3,
    #filter4,
    .form-select,
    .form-check {
        width: 70%;
        margin: auto;
    }
}

@media only screen and (min-width: 768px) {
    .row-mt {
        margin-top: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .row-mt-small {
        margin-top: 15px;
    }
}

@media only screen and (max-width: 575px) {
    .filter-container {
        margin-top: 20px;
    }
    .btn-clear-filter button{
        width: 100%;
    }
}
