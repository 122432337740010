.search-recipes{
    font-size: x-large;
    margin-top: 50px;
    margin-bottom: 30px;
}

.container-mb{
    margin-bottom: 80px;
}

.search-result{
    padding: 15px 30px 15px 30px;
}
.search-result .titel{
    color: #346d21;
    font-weight: bold;
    text-align: center;
}
.recipe-time{
    padding-left: 10px;
}
.recipe-image{
    border: 4px solid #96d481;
    width: 100%;
}
#hide{
    display: none;
}


@media only screen and (min-width: 1400px) {
    .recipe-image{
        max-height: 250px;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px){ 
    .recipe-image{
        max-height: 215px;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1199px){ 
    .recipe-image{
        max-height: 175px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px){ 
    .recipe-image{
        max-height: 200px;
    }
}
@media only screen and (min-width: 576px) and (max-width: 767px){ 
    .recipe-image{
        max-height: 320px;
    }
}
@media only screen and (max-width: 575px) { 
    .recipe-image{
        max-height: 175px;
    }
}
