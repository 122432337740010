@-webkit-keyframes ticker {
	0% { 
        -webkit-transform: translateX(0); 
        transform: translateX(0); 
    }
	100% { 
        -webkit-transform: translateX(-100%); 
        transform: translateX(-100%); 
    }
}
@keyframes ticker {
	0% { 
        -webkit-transform: translateX(0); 
        transform: translateX(0); 
    }
	100% { 
        -webkit-transform: translateX(-100%); 
        transform: translateX(-100%); 
    }
}
.ticker-container{
	width: 100%;
	overflow: hidden;
	border-top: 1px solid #e6e6e6;
	border-bottom: 1px solid #e6e6e6;
}
.ticker-wrap {
	-webkit-box-sizing: content-box;
	        box-sizing: content-box;
	width: 100%;
	padding-left: 100%;
	padding-top: 5px; 
	padding-bottom: 5px;
	background-color: #f5f5f7;
}
.ticker-move {
	display: inline-block;
	white-space: nowrap;
	padding-right: 100%;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	        animation-timing-function: linear;
	-webkit-animation-name: ticker;
	        animation-name: ticker;
	-webkit-animation-duration: 35s;
	        animation-duration: 35s;
}
.ticker-move:hover{
	-webkit-animation-play-state: paused;
	        animation-play-state: paused;
}
.ticker-item a:hover{
	color: black;
}
.ticker-item{
	display: inline-block;
	padding: 0 1rem;
    color: black;
}
.ticker-item a{
	color: #56b436;
    text-decoration: none;
}

@media only screen and (min-width: 576px) {
    .ticker-item,
    .ticker-item a{
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 575px) {
    .ticker-item,
    .ticker-item a{
        font-size: 1rem;
    }
}