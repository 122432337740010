.App {
  text-align: center;
}

html, body{
  overflow-x: clip;
}

.cookie-button-wrapper .btn-primary{
  margin: 10px;
  border: none;
}